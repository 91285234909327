import { StackAssertionError } from "./errors";
export function isNotNull(value) {
    return value !== null && value !== undefined;
}
/**
 * Assumes both objects are primitives, arrays, or non-function plain objects, and compares them deeply.
 *
 * Note that since they are assumed to be plain objects, this function does not compare prototypes.
 */
export function deepPlainEquals(obj1, obj2, options = {}) {
    if (typeof obj1 !== typeof obj2)
        return false;
    if (obj1 === obj2)
        return true;
    switch (typeof obj1) {
        case 'object': {
            if (!obj1 || !obj2)
                return false;
            if (Array.isArray(obj1) || Array.isArray(obj2)) {
                if (!Array.isArray(obj1) || !Array.isArray(obj2))
                    return false;
                if (obj1.length !== obj2.length)
                    return false;
                return obj1.every((v, i) => deepPlainEquals(v, obj2[i], options));
            }
            const entries1 = Object.entries(obj1).filter(([k, v]) => !options.ignoreUndefinedValues || v !== undefined);
            const entries2 = Object.entries(obj2).filter(([k, v]) => !options.ignoreUndefinedValues || v !== undefined);
            if (entries1.length !== entries2.length)
                return false;
            return entries1.every(([k, v1]) => {
                const e2 = entries2.find(([k2]) => k === k2);
                if (!e2)
                    return false;
                return deepPlainEquals(v1, e2[1], options);
            });
        }
        case 'undefined':
        case 'string':
        case 'number':
        case 'boolean':
        case 'bigint':
        case 'symbol':
        case 'function': {
            return false;
        }
        default: {
            throw new Error("Unexpected typeof " + typeof obj1);
        }
    }
}
export function deepPlainClone(obj) {
    if (typeof obj === 'function')
        throw new StackAssertionError("deepPlainClone does not support functions");
    if (typeof obj === 'symbol')
        throw new StackAssertionError("deepPlainClone does not support symbols");
    if (typeof obj !== 'object' || !obj)
        return obj;
    if (Array.isArray(obj))
        return obj.map(deepPlainClone);
    return Object.fromEntries(Object.entries(obj).map(([k, v]) => [k, deepPlainClone(v)]));
}
export function typedEntries(obj) {
    return Object.entries(obj);
}
export function typedFromEntries(entries) {
    return Object.fromEntries(entries);
}
export function typedKeys(obj) {
    return Object.keys(obj);
}
export function typedValues(obj) {
    return Object.values(obj);
}
export function typedAssign(target, source) {
    return Object.assign(target, source);
}
/**
 * Returns a new object with all undefined values removed. Useful when spreading optional parameters on an object, as
 * TypeScript's `Partial<XYZ>` type allows `undefined` values.
 */
export function filterUndefined(obj) {
    return Object.fromEntries(Object.entries(obj).filter(([, v]) => v !== undefined));
}
export function pick(obj, keys) {
    return Object.fromEntries(Object.entries(obj).filter(([k]) => keys.includes(k)));
}
export function omit(obj, keys) {
    return Object.fromEntries(Object.entries(obj).filter(([k]) => !keys.includes(k)));
}
export function split(obj, keys) {
    return [pick(obj, keys), omit(obj, keys)];
}
